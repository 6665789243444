export const ROUTES = {
    HOMEPAGE_ROUTE: '/',
    LOGIN_ROUTE: '/login',
    SIGNUP_ROUTE: '/signup',
    MYPROFILE_ROUTE: '/myprofile',
    COMPANIES_MANAGER_ROUTE: '/companies',
    INVOICES_MANAGER_ROUTE: '/invoices',
    PAYMENT_SHEET_MANAGER_ROUTE: '/paymentSheets',
    PDF_DOCUMENT: '/pdfDocument',
    LIBRARY_MANAGER_ROUTE: '/library',
    SCHOOL: '/escuela',
    SCHOOL_CHILDS: '/escuela/kids',
    SCHOOL_OLDS: '/escuela/mayores',
    SCHOOL_ONLINE: '/escuela/online',
    SCHOOL_RESOURCES: '/escuela/recursos',
    THECLUB: '/club',
    MEMBERS_MANAGER: '/membersManager',
    ACTIVITIES: '/activities',
    PRIVACY_POLICY: '/privacypolicy',
    LEGAL_NOTICE: '/legalnotice',
    CHESS_QUESTIONS_MANAGER: '/chessQuestionsManager',
    TORNEO_CARTEL: '/torneoCartel',
    TORNEO_BASES: '/torneoBases',
    TORNEO_INSCRIPCION: '/torneoInscripcion'
}
